<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        我是你的眼 跑向新視界
      </div>
      <p>2023年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="健身房數位轉型 拓展女性健身市場">
        <p class="image_desc">跑向新視界 推動視障跑力分析</p>
        <p class="image_desc">「跑向新視界」活動照片</p>
        <p class="image_desc">(圖片提供：本計畫)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          由H2U永悅健康進行運動數據公益之倡議，鼓勵視障選手勇敢挑戰自我，一起參與馬拉松賽事，希望能為運動平權的目標更邁進一步。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          「跑向新視界」是全台第一場馬拉松數據公益活動，由H2U永悅健康攜手中華視障路跑協會、輔大運動醫學中心，一同建構運動公益生態系，並藉由前導活動示範，進行運動數據公益之倡議，鼓勵視障選手勇敢挑戰自我，一起參與馬拉松賽事，希望能為運動平權的目標更邁進一步。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          透過賽事晶片、穿戴裝置及問卷等，收集跑者在馬拉松運動情境時身體活動表現之數據，結合跑步科學分析訓練服務，提供研究單位進行公益研究，以便了解視障跑者與陪跑員藉由跑力分析取得之訓練課表對其馬拉松賽事表現影響。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益</div>
        <div class="content-desc">
          永悅健康邀請到視障陪跑高雄團之視障跑者參與跑力分析計劃，並於路跑當天正式簽署啟動，協助視障跑者與視障陪跑員更效率且安全的跑步訓練，使視障者如同常人般體會運動樂趣及運動科技之進步，並持續引領更多視障者與陪跑員加入跑團，共同為運動平權目標奮鬥。
        </div>
      </div>
    </section>

  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'
export default {
  name: 'UseCase11107',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/111-07.jpeg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
